import GameState from "./game-state.ts";
import UserState from "./user-state.ts";

export class AppState {
  public userState: UserState;
  public gameState: GameState;

  constructor() {
    this.userState = new UserState();
    this.gameState = new GameState();
  }
}
