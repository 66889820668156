export default class TileBuffer {
  private static readonly TILE_STATE_MASK = 0x000000FF; // bits 0-7
  private static readonly PLAYER_MASK = 0x0000FF00; // bits 8-15
  private static readonly RESERVED_MASK = 0xFFFF0000; // bits 16-31

  private buffer: Uint32Array;

  constructor(width: number, height: number) {
    // (Chose u32 instead of u8 because buffers need to be aligned to 4 bytes. I could've padded the array and done bitwise ops in the shader, but this is easier for now.)
    // TODO - refactor to use u8 instead of u32
    this.buffer = new Uint32Array(width * height);

    // Fill with '9' to represent 'hidden' tiles.
    this.buffer.fill(9);
  }

  /**
   * Get the raw buffer (useful for WebGPU operations)
   */
  public getBuffer(): Uint32Array {
    return this.buffer;
  }

  /**
   * Get the tile state (0-255) at the given index.
   */
  public getTileState(index: number): number {
    this.validateIndex(index);
    return this.buffer[index] & TileBuffer.TILE_STATE_MASK;
  }

  /**
   * Get the player ownership (0-255) at the given index.
   */
  public getPlayerOwnership(index: number): number {
    this.validateIndex(index);
    return (this.buffer[index] & TileBuffer.PLAYER_MASK) >> 8;
  }

  /**
   * Set just the tile state, preserving other data.
   */
  public setTileState(index: number, tileState: number): void {
    this.validateIndex(index);
    this.validateRange(tileState, "Tile state");

    this.buffer[index] = (this.buffer[index] & ~TileBuffer.TILE_STATE_MASK) |
      (tileState & TileBuffer.TILE_STATE_MASK);
  }

  /**
   * Set just the player ownership, preserving other data.
   */
  public setPlayerOwnership(index: number, playerOwnership: number): void {
    this.validateIndex(index);
    this.validateRange(playerOwnership, "Player ownership");

    this.buffer[index] = (this.buffer[index] & ~TileBuffer.PLAYER_MASK) |
      ((playerOwnership << 8) & TileBuffer.PLAYER_MASK);
  }

  /**
   * Get the size of the buffer.
   */
  public size(): number {
    return this.buffer.length;
  }

  private validateIndex(index: number): void {
    if (index < 0 || index >= this.size()) {
      throw new Error(`Index ${index} is out of bounds (buffer size: ${this.size()})`);
    }
  }

  private validateRange(value: number, field: string): void {
    if (value < 0 || value > 255) {
      throw new Error(`${field} must be between 0 and 255, got ${value}`);
    }
  }
}
