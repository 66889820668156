export default class PlayerState {
  private _accountId: string;
  private _username: string;
  private _isAlive: boolean;
  private _isConnected: boolean;
  private _score: number;
  private _tilesRevealed: number;
  private _flagsPlaced: number;
  private _minesExploded: number;

  constructor(accountId: string, username: string) {
    this._accountId = accountId;
    this._username = username;
    this._isAlive = true;
    this._isConnected = true;
    this._score = 0;
    this._tilesRevealed = 0;
    this._flagsPlaced = 0;
    this._minesExploded = 0;
  }

  public get accountId(): string {
    return this._accountId;
  }
  public get username(): string {
    return this._username;
  }
  public get isAlive(): boolean {
    return this._isAlive;
  }
  public get isConnected(): boolean {
    return this._isConnected;
  }
  public get score(): number {
    return this._score;
  }
  public get tilesRevealed(): number {
    return this._tilesRevealed;
  }
  public get flagsPlaced(): number {
    return this._flagsPlaced;
  }
  public get minesExploded(): number {
    return this._minesExploded;
  }

  public revealTile(): void {
    this._tilesRevealed++;
  }

  public flagTile(): void {
    this._flagsPlaced++;
  }

  public unflagTile(): void {
    if (this._flagsPlaced <= 0) {
      throw new Error(`PlayerState: invalid flags placed count: '${this._flagsPlaced}'`);
    }

    this._flagsPlaced--;
  }

  public explodeMine(): void {
    this._minesExploded++;
  }

  public kill(): void {
    this._isAlive = false;
  }

  public disconnect(): void {
    this._isConnected = false;
  }
}
