import { AccountPayload, PlayerOwnedTileUpdates, WinCondition } from "../codec/incoming-payload.ts";
import PlayerState from "./player-state.ts";
import TileBuffer from "./tile-buffer.ts";

export enum GameStatus {
  LOBBY,
  IN_PROGRESS,
  FINISHED,
}

export default class GameState {
  private _gameId: string | null;
  private _joinCode: string | null;
  private _state: GameStatus | null;
  private _lobby: PlayerState[] | null;
  private _host: string | null;
  private _width: number | null;
  private _height: number | null;
  private _currentTimeLeftSeconds: number | null;
  private _tileBuffer: TileBuffer | null;
  private _winner: number | null;
  private _winCondition: WinCondition | null;
  private _tilesRevealed: number;
  private _flagsPlaced: number;
  private _minesExploded: number;

  constructor() {
    this._gameId = null;
    this._joinCode = null;
    this._state = null;
    this._lobby = null;
    this._host = null;
    this._width = null;
    this._height = null;
    this._currentTimeLeftSeconds = null;
    this._tileBuffer = null;
    this._winner = null;
    this._winCondition = null;
    this._tilesRevealed = 0;
    this._flagsPlaced = 0;
    this._minesExploded = 0;
  }

  public clear(): void {
    this._gameId = null;
    this._joinCode = null;
    this._state = null;
    this._lobby = null;
    this._host = null;
    this._width = null;
    this._height = null;
    this._currentTimeLeftSeconds = null;
    this._tileBuffer = null;
    this._winner = null;
    this._winCondition = null;
    this._tilesRevealed = 0;
    this._flagsPlaced = 0;
    this._minesExploded = 0;
  }

  public get gameId(): string | null {
    return this._gameId;
  }
  public get joinCode(): string | null {
    return this._joinCode;
  }
  public get state(): GameStatus | null {
    return this._state;
  }
  public get lobby(): PlayerState[] | null {
    return this._lobby;
  }
  public get host(): string | null {
    return this._host;
  }
  public get boardWidth(): number | null {
    return this._width;
  }
  public get boardHeight(): number | null {
    return this._height;
  }
  public get currentTimeLeftSeconds(): number | null {
    return this._currentTimeLeftSeconds;
  }
  public get tileBuffer(): TileBuffer | null {
    return this._tileBuffer;
  }
  public get winner(): number | null {
    return this._winner;
  }
  public get winCondition(): WinCondition | null {
    return this._winCondition;
  }
  public get tilesRevealed(): number {
    return this._tilesRevealed;
  }
  public get flagsPlaced(): number {
    return this._flagsPlaced;
  }
  public get minesExploded(): number {
    return this._minesExploded;
  }

  public createdPrivateGame(gameId: string, joinCode: string, hostAccountId: string, hostUsername: string): void {
    this._gameId = gameId;
    this._joinCode = joinCode;
    this._state = GameStatus.LOBBY;

    this._lobby = [new PlayerState(hostAccountId, hostUsername)];
    this._host = hostAccountId;
  }

  public joinedPrivateGame(gameId: string, joinCode: string, lobby: AccountPayload[]): void {
    this._gameId = gameId;
    this._joinCode = joinCode;
    this._state = GameStatus.LOBBY;

    this._lobby = lobby.map((accountPayload) => new PlayerState(accountPayload.accountId, accountPayload.username));

    if (this._lobby.length === 0) {
      throw new Error("GameState: lobby is empty (we're assuming that the first player in the lobby is the host)");
    }
    this._host = this._lobby[0].accountId;
  }

  public playerJoinedPrivateGame(gameId: string, accountId: string, username: string): void {
    if (this._gameId === null || this._state === null || this._lobby === null) {
      throw new Error("GameState: game is null");
    }
    if (this._state !== GameStatus.LOBBY) {
      throw new Error(`GameState: game is not in lobby state: '${this._state}'`);
    }
    if (this._gameId !== gameId) {
      throw new Error(`GameState: game id mismatch - expected '${this._gameId}', found '${gameId}'`);
    }

    // add player to lobby
    this._lobby.push(new PlayerState(accountId, username));
  }

  /**
   * Returns true if the lobby is now empty.
   */
  public playerLeftPrivateGame(gameId: string, accountId: string): boolean {
    if (this._gameId === null || this._state === null || this._lobby === null) {
      throw new Error("GameState: game is null");
    }
    if (this._gameId !== gameId) {
      throw new Error(`GameState: game id mismatch - expected '${this._gameId}', found '${gameId}'`);
    }

    // a player can leave the lobby even if the game is in progress
    // no need to check if 'state === GameStatus.LOBBY'

    switch (this._state) {
      case GameStatus.LOBBY: {
        // remove player from lobby
        this._lobby = this._lobby.filter((player) => player.accountId !== accountId);

        // if the player that left was the host, assign a new host
        if (this._lobby.length > 0) {
          // (we're assuming that the first player in the lobby is the host)
          this._host = this._lobby[0].accountId;
          return false;
        } else {
          this._host = null;
          return true;
        }
      }
      case GameStatus.IN_PROGRESS: {
        // find the index of the player that left
        const playerIndex = this._lobby.findIndex((player) => player.accountId === accountId);
        if (playerIndex === -1) {
          throw new Error(`GameState: player '${accountId}' not found in lobby`);
        }

        // mark them as disconnected
        this._lobby[playerIndex].disconnect();

        return false;
      }
      case GameStatus.FINISHED: {
        // find the index of the player that left
        const playerIndex = this._lobby.findIndex((player) => player.accountId === accountId);
        if (playerIndex === -1) {
          throw new Error(`GameState: player '${accountId}' not found in lobby`);
        }

        // mark them as disconnected
        this._lobby[playerIndex].disconnect();

        return false;
      }
      default:
        throw new Error(`GameState: unexpected state: '${this._state}'`);
    }
  }

  public startedPrivateGame(gameId: string, width: number, height: number, maxDurationSeconds: number): void {
    if (this._gameId === null || this._state === null) {
      throw new Error("GameState: game is null");
    }
    if (this._state !== GameStatus.LOBBY) {
      throw new Error(`GameState: game is not in lobby state: '${this._state}'`);
    }
    if (this._gameId !== gameId) {
      throw new Error(`GameState: game id mismatch - expected '${this._gameId}', found '${gameId}'`);
    }

    // mark game in-progress
    this._state = GameStatus.IN_PROGRESS;

    // set board dimensions
    this._width = width;
    this._height = height;

    // set current time left
    this._currentTimeLeftSeconds = maxDurationSeconds - 1;

    // initialize tile states
    this._tileBuffer = new TileBuffer(this._width, this._height);
  }

  public countDownCurrentTimeLeft(): void {
    if (this._currentTimeLeftSeconds === null) {
      throw new Error("GameState: current time left is null");
    }
    if (this._currentTimeLeftSeconds <= 0) {
      throw new Error("GameState: current time left is already 0");
    }

    this._currentTimeLeftSeconds -= 1;
  }

  public gameStateUpdate(
    gameId: string,
    playerOwnedTileUpdates: PlayerOwnedTileUpdates[],
    deadPlayers: number[],
  ): void {
    if (this._gameId === null || this._state === null || this._tileBuffer === null) {
      throw new Error("GameState: game is null");
    }
    if (this._lobby === null) {
      throw new Error("GameState: lobby is null");
    }
    if (this._state !== GameStatus.IN_PROGRESS) {
      throw new Error(`GameState: game is not in 'IN_PROGRESS' state: '${this._state}'`);
    }
    if (this._gameId !== gameId) {
      throw new Error(`GameState: game id mismatch - expected '${this._gameId}', found '${gameId}'`);
    }

    // bulk update tile states
    for (const playerOwnedTileUpdate of playerOwnedTileUpdates) {
      for (const tileUpdate of playerOwnedTileUpdate.tileUpdates) {
        this._tileBuffer.setTileState(tileUpdate.index, tileUpdate.newType);
        this._tileBuffer.setPlayerOwnership(tileUpdate.index, playerOwnedTileUpdate.playerId);

        // update player stats
        switch (tileUpdate.newType) {
          case 0:
          case 1:
          case 2:
          case 3:
          case 4:
          case 5:
          case 6:
          case 7:
          case 8:
            this._tilesRevealed += 1;
            this._lobby[playerOwnedTileUpdate.playerId].revealTile();
            break;
          case 9: // hidden tile (which means this is a newly unflagged flag)
            this._flagsPlaced -= 1;
            this._lobby[playerOwnedTileUpdate.playerId].unflagTile();
            break;
          case 10:
            this._flagsPlaced += 1;
            this._lobby[playerOwnedTileUpdate.playerId].flagTile();
            break;
          case 11:
          case 12:
          case 13:
          case 14:
            // N/A (unimplemented tile types)
            break;
          case 15:
            this._minesExploded += 1;
            this._lobby[playerOwnedTileUpdate.playerId].explodeMine();
            break;
          default:
            throw new Error(`GameState: unexpected tile state: '${tileUpdate.newType}'`);
        }
      }
    }

    // TODO - display alive/dead users to player
    // TODO - handle game over for current player
    for (const deadPlayer of deadPlayers) {
      console.log(`Player '${deadPlayer}' is dead.`);
      this._lobby[deadPlayer].kill();
    }
  }

  public getGameOver(gameId: string, winner: number, winCondition: WinCondition): void {
    if (
      this._gameId === null || this._state === null || this._lobby === null || this._width === null ||
      this._height === null
    ) {
      throw new Error("GameState: game is null");
    }
    if (this._state !== GameStatus.IN_PROGRESS) {
      throw new Error(`GameState: game is not in 'IN_PROGRESS' state: '${this._state}'`);
    }
    if (this._gameId !== gameId) {
      throw new Error(`GameState: game id mismatch - expected '${this._gameId}', found '${gameId}'`);
    }

    this._state = GameStatus.FINISHED;
    this._winner = winner;
    this._winCondition = winCondition;

    // validate win condition
    switch (winCondition) {
      case WinCondition.LastPlayerAlive: {
        const totalAlivePlayers: number = this._lobby.filter((player) => player.isAlive).length;
        if (totalAlivePlayers !== 1) {
          throw new Error(`GameState: unexpected total alive players: '${totalAlivePlayers}'`);
        }
        break;
      }
      case WinCondition.EntireBoardRevealedOrFlagged: {
        const totalTiles: number = this._width * this._height;

        // double-check that all tiles have been 'activated' (aka flagged or revealed (including exploded mines))
        const totalActivatedTiles: number = this._tilesRevealed + this._flagsPlaced + this._minesExploded;
        if (totalActivatedTiles !== totalTiles) {
          throw new Error(
            `GameState: unexpected total 'activated' tiles: '${totalActivatedTiles}' (expected '${totalTiles}')`,
          );
        }

        // double-check that the sum of all players' tile stats equal total 'activated' tiles
        const totalPlayersModifiedTiles: number = this._lobby.reduce(
          (totalPlayersModifiedTiles: number, player: PlayerState) =>
            totalPlayersModifiedTiles + player.tilesRevealed + player.flagsPlaced + player.minesExploded,
          0,
        );
        if (totalPlayersModifiedTiles !== totalActivatedTiles) {
          throw new Error(
            `GameState: unexpected total players modified tiles: '${totalPlayersModifiedTiles}' (expected '${totalActivatedTiles}')`,
          );
        }

        break;
      }
      case WinCondition.TimeLimit: {
        if (this._currentTimeLeftSeconds !== 0) {
          throw new Error(`GameState: unexpected current time left: '${this._currentTimeLeftSeconds}'`);
        }
        break;
      }
      default:
        throw new Error(`GameState: unexpected win condition: '${winCondition}'`);
    }
  }
}
