export default class UserState {
  private _accountId: string | null;
  private _username: string | null;

  constructor() {
    this._accountId = null;
    this._username = null;
  }

  public get accountId(): string | null {
    return this._accountId;
  }
  public get username(): string | null {
    return this._username;
  }

  public logIn(accountId: string, username: string): void {
    this._accountId = accountId;
    this._username = username;
  }

  public logOut(): void {
    this._accountId = null;
    this._username = null;
  }
}
